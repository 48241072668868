import { createRouter, createWebHistory } from 'vue-router'
import { getCookie } from '@/utilities/cookieTools'

// Auth Animated Routes
const authAnimatedChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/animated/SignIn.vue')
  }
]

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { name: 'Error 500', isBanner: true },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { name: 'Maintenance', isBanner: true },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]

const routes = [
  // Default Pages
  {
    path: '/',
    name: 'login',
    component: () => import('../layouts/guest/AuthAnimated.vue'),
    children: authAnimatedChildRoutes('default')
  },

  // Errors Pages
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: errorRoutes('errors')
  }
]

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = getCookie('token')

  if (to.meta.auth && token == null) {
    window.location.href = process.env.VUE_APP_DIRECT
  }
  const exists = router.resolve(to.path).matched.length > 0
  if (!exists) {
    next({ name: 'errors.404' })
  } else {
    next()
  }
})

export default router
