import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Connect API
import VueAxios from 'vue-axios'
import axios from './utilities/axios'

// Library Components
import BootstrapVue3 from 'bootstrap-vue-3'
import VueSweetalert2 from 'vue-sweetalert2'
import CounterUp from 'vue3-autocounter'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
require('waypoints/lib/noframework.waypoints.min')

const app = createApp(App)
app.use(store).use(router).use(i18n)

// Library Components
app.use(VueSweetalert2)
app.use(BootstrapVue3)
app.component('counter-up', CounterUp)

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)
app.mixin(globalMixin)

// Axios Connection
app.use(VueAxios, axios)
app.config.globalProperties.$axios = { ...axios }

app.mount('#app')

export default app
